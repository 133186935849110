import moment from "moment"

export const getCustomerRequestTrip = (tripReducer) => {
    return tripReducer.customerToRequest ?? ''
}

export const getOriginRequestTrip = (tripReducer) => {
    return {
        desc: tripReducer.pickUpLocation?.desc?.description ?? '',
        location: tripReducer.pickUpLocation?.location ?? {}
    }
}

export const getDestinationRequestTrip = (tripReducer) => {
    return {
        desc: tripReducer.dropOffLocation?.desc?.description ?? '',
        location: tripReducer.dropOffLocation?.location ?? {}
    }
}

export const getPickUpDateRequestTrip = (tripReducer) => {
    return tripReducer.pickUpDate ?? ''
}

export const getQuantityPassengerRequestTrip = (tripReducer) => {
    if (tripReducer.tripToModify?.numPassengers) {
        return tripReducer.tripToModify.numPassengers
    }
    return tripReducer.numPassengers ?? 1
}

export const getHasWheelChairsRequestTrip = (tripReducer) => {
    /*
    First condition is for when modifying a trip, it retrieves the value from the trip in progress.
    The second condition is for when repeating a trip, it retrieves the value from the previous trip.
    The third one is for when creating a new trip, it retrieves the value from the customer's profile.
    If everything fails it appears unchecked by default.
    */
    return tripReducer.tripToModify?.wheelChairs ?? tripReducer?.wheelChairs ?? tripReducer.customerToRequest?.hasWheelChair ?? false
}

export const getLuggageRequestTrip = (tripReducer) => {
    if (tripReducer.tripToModify?.quantityLuggage) {
        return tripReducer.tripToModify.quantityLuggage
    }
    return tripReducer.quantityLuggage ?? 0
}

export const isMultipleTripOutbound = (trip) => {
    if (trip == null)
        return false
    return (trip.isMultipleRequest && !trip.isReturn)
}

export const outboundHasReturn = (trip, listOfTrips) => {
    if (trip == null)
        return false
    return listOfTrips.filter(item => item.tripResponse != null).some(
        item => moment(item.tripResponse.schedulePickUpTime).isSame(moment(trip.schedulePickUpTime), 'day') && item.tripResponse.isReturn && item.tripId != trip.tripId)
}

export const isMultipleTripReturn = (trip) => {
    if (trip == null)
        return false
    return (trip.isMultipleRequest && trip.isReturn)
}

export const changeTimeToTrip = (trip, newTime) => {
    let originalSchedule = moment(trip?.requestPickUpTime)
    let newTimeSplitted = null
    if (typeof newTime === "string")
        newTimeSplitted = newTime.split(":")
    else
        newTimeSplitted = moment(newTime).format("HH:mm").split(":")
    originalSchedule.set({ hour: parseInt(newTimeSplitted[0]), minute: parseInt(newTimeSplitted[1]) })
    return originalSchedule
}

export const getIsRequestByDropOff = (tripReducer) => {
    return tripReducer.isRequestByDropOff ?? false
}

// Filter trips that are unique in the day
export const filterTripsByUniqueDays = (listOfTrips) => {
    const counter = {}

    listOfTrips.forEach((item) => {
        const date = item.requestPickUpTime.split('T')[0]

        if (counter[date]) {
            counter[date]++
        } else {
            counter[date] = 1;
        }
    })


    return listOfTrips.filter((item) => counter[item.requestPickUpTime.split('T')[0]] == 1)
}

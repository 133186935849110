import { tripConstants } from '../../constants/trip_constants'
import { tripService } from '../../services/tripService'
import { googleMapsService } from '../../services/googleMapsService'
import { alertActions } from '../actions/alert_actions'
import { userActions } from '../actions/user_actions'
import i18next from 'i18next'
import moment from 'moment'
import { userService } from '../../services/userService'
import { serviceService } from '../../services/servicesService'
import { serviceActions } from './services_actions'
import { ERROR_CODES, REGULAR_SERVICES_TYPES, SERVICES_TYPE, ZONES_STOPS_TYPE } from '../../constants/types'
import { regularLine_actions } from './regularLine_actions'
import { getDropOffLocationAddress, getPickUpLocationAddress, handleTripError } from '../../helpers/tools'
import { history } from '../../helpers/history'
import { multipleTripsActions } from './multipleTrips_action'

const getValue = value =>
    typeof value === 'string'
        ? value.toUpperCase()
        : value
            ? value.toString()
            : ''

export const tripActions = {
    getAll,
    getById,
    insert,
    update,
    remove,
    getLocation,
    clear,
    setMarker,
    clearMarker,
    getTripsByCustomerId,
    cancelTrip,
    clearAllMarkers,
    setTrip,
    acceptTrip,
    rejectTrip,
    getTripServices,
    selectServiceOpen,
    selectServiceClose,
    setServiceNotServiceLine,
    setServiceServiceLine,
    setOrigin,
    setDestination,
    closeModalRequestTrip,
    openModalRequestTrip,
    customerCanPerformTrip,
    getTripStatuses,
    setOriginDistanceTime,
    setDestinationDistanceTime,
    repeatTrip,
    cleanRepeatTrip,
    setModifyTrip,
    modifyTrip,
    acceptModifyTrip,
    getTrips,
    setTripsFilterDate,
    getTripsPending,
    clearAllResults,
    confirmTripVehicle,
    cancelTripByDriverUnavailable,
    getAllKPI,
    getConfirmedTripsKPI,
    openModalExpeditions,
    closeModalExpeditions,
    getJourneyKPI,
    getConfirmedGroupedTripsKPI,
    closeModalSelectScheduledPickUp,
    filterTrips,
    updateDateOfTripFromDatePicker,
    delegateTripVehicle,
    saveTableFilters,
    cleanFilters,
    closeReturnTripModal,
}

function getAll() {
    return dispatch => {
        dispatch(request())
        tripService.getAll().then(
            results => {
                const all = [...results]
                dispatch(success(results, all))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getAll())
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.trips.forbiddenGetAll')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.trips.getAllError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: tripConstants.GETALL_REQUEST }
    }

    function success(results, allTrips) {
        return { type: tripConstants.GETALL_SUCCESS, results, allTrips }
    }

    function failure(error) {
        return { type: tripConstants.GETALL_FAILURE, error }
    }
}

function filterTrips(filters, allTrips) {
    return dispatch => {
        let filtered = [...allTrips]
        if (filters != null) {
            filtered = filterPlainArrayContains(allTrips, filters)
        }
        dispatch(success(filtered, allTrips))
        dispatch(setFilterTrip(filters))
    }
    function success(results, allTrips) {
        return { type: tripConstants.GETALL_SUCCESS, results, allTrips }
    }
    function setFilterTrip(filters) {
        return { type: tripConstants.SET_FILTER_TRIPS, filters }
    }
}

function openModalExpeditions(expeditions) {
    return dispatch => {
        dispatch(request(expeditions))
    }

    function request(expeditions) {
        return { type: tripConstants.OPEN_MODAL_SHOW_EXPEDITIONS, expeditions }
    }
}

function closeModalExpeditions(expeditions) {
    return dispatch => {
        dispatch(request(expeditions))
    }

    function request() {
        return { type: tripConstants.CLOSE_MODAL_SHOW_EXPEDITIONS }
    }
}

function getTrips(filters = null, startDate = null, finishDate = null) {
    return dispatch => {
        dispatch(request())
        tripService.getTrips(startDate, finishDate).then(
            results => {
                const all = [...results]
                if (filters) {
                    results = filterPlainArrayContains(results, filters)
                }
                dispatch(success(results, all))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(
                            getTrips(
                                (filters = null),
                                (startDate = null),
                                (finishDate = null),
                            ),
                        )
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.trips.forbiddenGetAll')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.trips.getAllError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: tripConstants.GETALL_REQUEST }
    }

    function success(results, allTrips) {
        return { type: tripConstants.GETALL_SUCCESS, results, allTrips }
    }

    function failure(error) {
        return { type: tripConstants.GETALL_FAILURE, error }
    }
}

function getTripsPending(filters = null, startDate = null, finishDate = null) {
    return dispatch => {
        dispatch(request())
        tripService.getTripsPendingToDriver(startDate, finishDate).then(
            results => {
                const all = [...results]
                if (filters) {
                    results = filterPlainArrayContains(results, filters)
                }
                dispatch(success(results, all))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(
                        getTripsPending(
                            (filters = null),
                            (startDate = null),
                            (finishDate = null),
                        ),
                    )
                } else {
                    dispatch(alertActions.error(i18next.t('services.trips.getAllError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: tripConstants.GETALL_REQUEST }
    }

    function success(results, allTrips) {
        return { type: tripConstants.GETALL_SUCCESS, results, allTrips }
    }

    function failure(error) {
        return { type: tripConstants.GETALL_FAILURE, error }
    }
}

function setTripsFilterDate(typeDate, value) {
    return dispatch => {
        dispatch(request(typeDate, value))
    }

    function request(typeDate, value) {
        return { type: tripConstants.SET_FILTER_TRIPS_DATES, typeDate, value }
    }
}

function getTripStatuses() {
    return dispatch => {
        dispatch(request())
        const lang = i18next.language
        tripService.getTripStatuses().then(
            results => {
                const prof = makeEnum(results)
                dispatch(success(prof))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getTripStatuses(lang))
                } else {
                    dispatch(alertActions.error(i18next.t('services.trips.getAllError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: tripConstants.GET_TRIPS_TYPES_REQUEST }
    }

    function success(results) {
        return { type: tripConstants.GET_TRIPS_TYPES_SUCCESS, results }
    }

    function failure(error) {
        return { type: tripConstants.GET_TRIPS_TYPES_FAILURE, error }
    }
}

function getById(id, redirectToResume = false) {
    return dispatch => {
        dispatch(request(id))
        tripService.getById(id).then(
            trip => {
                dispatch(success(trip))
                if (!trip || trip.tripId == null) {
                    dispatch(alertActions.error(i18next.t('services.trips.getByIdError')))
                    return
                }

                if (redirectToResume) {
                    history.push('/resumeTrip?id=' + id)
                }
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getById(id))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.trips.forbiddenGetById')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.trips.getByIdError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: tripConstants.GETBYID_REQUEST, id }
    }

    function success(trip) {
        return { type: tripConstants.GETBYID_SUCCESS, trip }
    }

    function failure(error) {
        return { type: tripConstants.GETBYID_FAILURE, error }
    }
}

function getTripsByCustomerId(id, filters = null) {
    return dispatch => {
        dispatch(request(id))
        tripService.getTripsByCustomerId(id).then(
            trips => {
                let all = trips
                if (filters != null) {
                    trips = filterPlainArrayContains(trips, filters)
                }
                dispatch(success(trips, all))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(getTripsByCustomerId(id, filters))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.trips.forbiddenGetById')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.trips.getByIdError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: tripConstants.GET_TRIPS_CUSTOMER_REQUEST, id }
    }

    function success(trips, all) {
        return { type: tripConstants.GET_TRIPS_CUSTOMER_SUCCESS, trips, all }
    }

    function failure(error) {
        return { type: tripConstants.GET_TRIPS_CUSTOMER_FAILURE, error }
    }
}

function setTrip(trip) {
    return dispatch => {
        dispatch(request(trip))
    }

    function request(trip) {
        return { type: tripConstants.SET_TRIP, trip }
    }
}

function customerCanPerformTrip(customerId, pickUpStartTime, relatedCustomerId) {
    return dispatch => {
        dispatch(request(customerId, pickUpStartTime, relatedCustomerId))
        tripService.customerCanPerformTrip(customerId, pickUpStartTime, relatedCustomerId).then(
            result => {
                dispatch(success(result))
            },
            error => {
                try {
                    let parsedError = JSON.parse(error)
                    let detailError = parsedError.detail
                    switch (true) {
                        case detailError.includes('CustomerDisabled'):
                            detailError = i18next.t('services.trips.CustomerDisabled')
                            break
                        default:
                            break
                    }
                    dispatch(failure(detailError))
                    dispatch(alertActions.error(detailError))
                } catch (e) {
                    dispatch(failure(error))
                }
                dispatch(failure(error))
            },
        )
    }

    function request(customerId, pickUpStartTime, relatedCustomerId) {
        return {
            type: tripConstants.CUSTOMER_CAN_PERFORM_TRIP_REQUEST,
            customerId,
            relatedCustomerId,
            pickUpStartTime,
        }
    }

    function success(result) {
        return { type: tripConstants.CUSTOMER_CAN_PERFORM_TRIP_SUCCESS, result }
    }

    function failure(error) {
        return { type: tripConstants.CUSTOMER_CAN_PERFORM_TRIP_FAILURE, error }
    }
}

function cancelTrip(id, startDate = null, endDate = null) {
    return dispatch => {
        dispatch(request(id))
        tripService.cancelTrip(id).then(
            trip => {
                dispatch(success(trip))
                if (trip.cancellationMinutesExpired) {
                    dispatch(alertActions.warning(i18next.t('services.trips.CancellationMinutesExpired')))
                }
                dispatch(alertActions.success(i18next.t('services.trips.cancelSuccess')))
                dispatch(getTripsByCustomerId(trip.customerId))
                refreshTripsAfterCancelRequest(dispatch, startDate, endDate)
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(cancelTrip(id))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.trips.forbiddenUpdate')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.trips.cancelError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: tripConstants.CANCEL_TRIP_REQUEST, id }
    }

    function success(trip) {
        return { type: tripConstants.CANCEL_TRIP_SUCCESS, trip }
    }

    function failure(error) {
        return { type: tripConstants.CANCEL_TRIP_FAILURE, error }
    }
}


function refreshTripsAfterCancelRequest(dispatch, startDate = null, endDate = null) {
    // to refresh data table
    const location = window.location.pathname
    switch (true) {
        case location.includes("tripsPending"):
            dispatch(getTripsPending(null, startDate, endDate))
            break;
        case location.includes("tripsAll"):
            dispatch(getTrips(null, startDate, endDate))
            break;
        case location.includes("journeysFlex"):
            dispatch(serviceActions.getJourneys(null, startDate, endDate))
            break;
        case location.includes("journeysWheel"):
            dispatch(serviceActions.getWheelJourneys(null, startDate, endDate))
            break;
        case location.includes("customers/trips"):
            const customerId = location.split('/').pop()
            dispatch(tripActions.getTripsByCustomerId(customerId))
            break;
        case location.includes("/journeys/trips"):
            history.goBack()
            break;
        default:
            window.location.reload()
    }
}



function cancelTripByDriverUnavailable(id) {
    return dispatch => {
        dispatch(request(id))
        tripService.cancelTripByDriverUnavailable(id).then(
            trip => {
                dispatch(success(trip))
                if (trip.cancellationMinutesExpired) {
                    dispatch(alertActions.warning(i18next.t('services.trips.CancellationMinutesExpired')))
                }
                dispatch(alertActions.success(i18next.t('services.trips.cancelSuccess')))
                dispatch(getTripsByCustomerId(trip.customerId))
                refreshTripsAfterCancelRequest(dispatch)

            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(cancelTripByDriverUnavailable(id))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.trips.forbiddenUpdate')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.trips.cancelError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: tripConstants.CANCEL_TRIP_REQUEST, id }
    }

    function success(trip) {
        return { type: tripConstants.CANCEL_TRIP_SUCCESS, trip }
    }

    function failure(error) {
        return { type: tripConstants.CANCEL_TRIP_FAILURE, error }
    }
}

function getTripServices(trip, customerId, noRestriction, isRetry = false) {
    return dispatch => {
        dispatch(request())
        tripService
            .customerCanPerformTrip(
                customerId,
                trip.requestPickUpStartTime.toISOString(),
                trip.relatedCustomer?.id ?? ''
            )
            .then(result => {
                if (result.canPerformTrip) {
                    tripService
                        .getTripServices(trip, customerId, noRestriction)
                        .then(
                            services => {
                                if (services.length >= 1) {
                                    if (services.length == 1) {
                                        const service = services[0]
                                        // check if is regularLine
                                        if (!REGULAR_SERVICES_TYPES.includes(service.serviceType)) {
                                            if (service.originZone.zoneType == ZONES_STOPS_TYPE.withoutStops && service.destinationZone.zoneType == ZONES_STOPS_TYPE.withoutStops) {
                                                cleanMyAddress(trip)
                                                dispatch(insert(trip, customerId, service.id, noRestriction, isRetry))
                                            } else {
                                                dispatch(setServiceNotServiceLine(service, trip, customerId, noRestriction, isRetry))
                                            }
                                        } else {
                                            dispatch(setServiceServiceLine(service, trip, customerId))
                                        }
                                    } else {
                                        // open modal to select service
                                        dispatch(selectServiceOpen(trip, customerId, services))
                                    }
                                } else {
                                    dispatch(alertActions.error(i18next.t('services.trips.notResult')))
                                }
                                //dispatch(success(services));
                            },
                            error => {
                                let detailError = 'Se ha producido un error'
                                if (
                                    error === 401 &&
                                    userService.existRefreshToken()
                                ) {
                                    dispatch(userActions.refreshToken())
                                    dispatch(getTripServices(trip, customerId, noRestriction))
                                } else {
                                    try {
                                        let parsedError = JSON.parse(error)
                                        detailError = parsedError.detail
                                        switch (true) {
                                            default:
                                                parsedError.map(itemError => dispatch(
                                                    alertActions.error(itemError.detail)
                                                ))
                                        }
                                        dispatch(failure(detailError))
                                    } catch (e) {
                                        detailError = i18next.t('services.trips.getServices.TripNotProcessed')
                                    }
                                }
                                //dispatch(alertActions.error(i18next.t('services.trips.notResult')));
                                dispatch(failure(error))
                            },
                        )
                } else {
                    dispatch(
                        alertActions.error(
                            i18next.t('services.trips.canPerformTripError') +
                            moment(result.nextAvailableTime).format(
                                'HH:mm',
                            ),
                        ),
                    )
                    dispatch(failure('can´t PerformTrip'))
                }
            })
    }

    function request() {
        return { type: tripConstants.GET_TRIP_SERVICES_REQUEST }
    }

    function success(services) {
        return { type: tripConstants.GET_TRIP_SERVICES_SUCCESS, services }
    }

    function failure(error) {
        return { type: tripConstants.GET_TRIP_SERVICES_FAILURE, error }
    }
}

function cleanMyAddress(requestTrip) {
    if (requestTrip?.pickUpLocation?.id === 'my_address') {
        requestTrip.pickUpLocation.id = ''
    }
    if (requestTrip?.dropOffLocation?.id === 'my_address') {
        requestTrip.dropOffLocation.id = ''
    }
}

function setOrigin(item, tripReducer, isServiceLine, noRestriction, isReturn, outboundTripId) {
    return dispatch => {
        let serviceSelected = tripReducer.serviceSelected
        let requestTrip = tripReducer.requestTrip
        let isRetry = tripReducer.requestTripError
        // ask if item was selected or is clean origin
        if (item) {
            requestTrip.originalPickUp = { ...requestTrip.pickUpLocation }
            requestTrip.pickUpLocation.address = item.address
            requestTrip.pickUpLocation.lat = item.point.lat
            requestTrip.pickUpLocation.lon = item.point.lon
            requestTrip.pickUpLocation.id = item.id
            requestTrip.pickUpStopName = item.name
        } else {
            requestTrip.pickUpLocation = { ...requestTrip.originalPickUp }
            requestTrip.pickUpLocation.id = requestTrip.originalPickUp.id
        }
        if (!isServiceLine) {
            if (serviceSelected.destinationZone.zoneType == ZONES_STOPS_TYPE.withoutStops) {
                cleanMyAddress(requestTrip)
                dispatch(insert(requestTrip, tripReducer.customerId, serviceSelected.id, noRestriction, isRetry))
            } else if (
                item &&
                item.id != 'my_address' &&
                serviceSelected.destinationZone.zoneType != ZONES_STOPS_TYPE.withStops &&
                (serviceSelected.destinationZone.zoneType == ZONES_STOPS_TYPE.withoutStops ||
                    serviceSelected.destinationZone.zoneType == ZONES_STOPS_TYPE.mixedOriginOrDestination) &&
                serviceSelected.destinationZone.zoneType != ZONES_STOPS_TYPE.mixedOriginAndDestination
            ) {
                cleanMyAddress(requestTrip)
                dispatch(insert(requestTrip, tripReducer.customerId, serviceSelected.id, noRestriction, isRetry))
            } else {
                if (item) {
                    dispatch(request(item))
                    dispatch(
                        openModalRequestTrip(
                            serviceSelected.destinationZone.zoneType == ZONES_STOPS_TYPE.mixedOriginAndDestination && !window.selectAutoCompleteDestination ? i18next.t('trips.maps.markers.selectDestinationAND')
                                : i18next.t('trips.maps.markers.selectDestination'),
                        ))
                }
                else {
                    dispatch(request(item))
                    dispatch(setServiceNotServiceLine(tripReducer.serviceSelected, tripReducer.requestTrip, tripReducer.customerId, noRestriction, isRetry))
                }

            }
        } else {
            if (item) {
                if (serviceSelected.serviceType == SERVICES_TYPE.regularService) {
                    if (getCountAllowDestination(item, Object.assign({}, tripReducer)) > 0 && tripReducer.possibleDestinations.length > 0) {
                        dispatch(request(item))
                        dispatch(openModalRequestTrip(i18next.t('trips.maps.markers.selectDestination')))
                    }
                    else
                        dispatch(alertActions.warning((i18next.t('trips.maps.index.stopEmpty'))))
                } else {
                    // is regular service with schedule
                    let requestDateTime = moment(requestTrip.requestPickUpStartTime).toDate()
                    dispatch(regularLine_actions.getRegularLinePossibleDropoffs(requestDateTime, item))
                }
            } else {
                dispatch(request(item))
            }

        }
    }
    function request(stop) {
        return { type: tripConstants.SET_ORIGIN, stop }
    }
}

function getCountAllowDestination(stop, tripReducer) {
    let allowDestinations = Object.assign([], tripReducer.possibleDestinations)
    if (!stop.allowOppositeDirections)
        allowDestinations = Object.assign([], allowDestinations.splice(allowDestinations.indexOf(stop), allowDestinations.length))
    allowDestinations = Object.assign([], allowDestinations.filter(x => x.id != stop.id))
    return allowDestinations.length
}


function setOriginDistanceTime(distanceTimeData) {
    return dispatch => {
        dispatch(request(distanceTimeData))
    }
    function request(distanceTimeData) {
        return {
            type: tripConstants.SET_ORIGIN_DISTANCE_TIME,
            distanceTimeData,
        }
    }
}

function setDestinationDistanceTime(distanceTimeData) {
    return dispatch => {
        dispatch(request(distanceTimeData))
    }
    function request(distanceTimeData) {
        return {
            type: tripConstants.SET_DESTINATION_DISTANCE_TIME,
            distanceTimeData,
        }
    }
}

function setDestination(item, tripReducer, noRestriction, isReturn, outboundTripId) {
    return dispatch => {
        let serviceSelected = tripReducer.serviceSelected
        let isRetry = tripReducer.requestTripError
        let requestTrip = tripReducer.requestTrip
        dispatch(request(item))
        requestTrip.dropOffLocation.address = item.address
        requestTrip.dropOffLocation.lat = item.point.lat
        requestTrip.dropOffLocation.lon = item.point.lon
        requestTrip.dropOffLocation.id = item.id
        requestTrip.dropOffStopName = item.name
        requestTrip.serviceLineDirection = tripReducer.serviceLineDirection
        if (serviceSelected.serviceType != SERVICES_TYPE.regularWithSchedule) {
            cleanMyAddress(requestTrip)
            dispatch(insert(requestTrip, tripReducer.customerId, serviceSelected.id, noRestriction, isRetry))
        } else {
            dispatch(regularLine_actions.getRegularLineStopScheduledHours(requestTrip.requestPickUpStartTime, tripReducer.originStop?.id, item.id, requestTrip.isRequestByDropOff))
        }

    }
    function request(stop) {
        return { type: tripConstants.SET_DESTINATION, stop }
    }
}

function updateDateOfTripFromDatePicker(date) {
    return dispatch => {
        dispatch(request(date))
    }
    function request(date) {
        return { type: tripConstants.UPDATE_DATE_TRIP, date }
    }
}

function setServiceNotServiceLine(service, trip, customerId, noRestriction, isRetry = false) {
    return dispatch => {
        let message
        let serviceTypeOrigin = service.originZone.zoneType
        let serviceTypeDestination = service.destinationZone.zoneType
        if (serviceTypeOrigin == ZONES_STOPS_TYPE.withoutStops && serviceTypeDestination == ZONES_STOPS_TYPE.withoutStops) {
            cleanMyAddress(trip)
            dispatch(insert(trip, customerId, service.id, noRestriction, isRetry))
        } else {
            switch (true) {
                case serviceTypeOrigin === ZONES_STOPS_TYPE.withStops:
                    message = i18next.t('trips.maps.markers.onlyStopsOrigin')
                    break
                case (serviceTypeOrigin === ZONES_STOPS_TYPE.withoutStops ||
                    serviceTypeOrigin === ZONES_STOPS_TYPE.mixedOriginOrDestination ||
                    serviceTypeOrigin === ZONES_STOPS_TYPE.mixedOriginAndDestination) &&
                    serviceTypeDestination === ZONES_STOPS_TYPE.withoutStops:
                    message = !window.selectAutoCompleteOrigin ? i18next.t('trips.maps.markers.multipleOriginDestinationsWithoutStops') : i18next.t('trips.maps.markers.onlyStopsOrigin')
                    break
                case serviceTypeOrigin === ZONES_STOPS_TYPE.withoutStops ||
                    serviceTypeOrigin === ZONES_STOPS_TYPE.mixedOriginOrDestination ||
                    serviceTypeOrigin === ZONES_STOPS_TYPE.mixedOriginAndDestination:
                    message = !window.selectAutoCompleteOrigin ? i18next.t('trips.maps.markers.multipleOrigin') : i18next.t('trips.maps.markers.onlyStopsOrigin')
                    break
                default:
                    message = i18next.t('trips.maps.markers.onlyStopsOrigin')
            }
            dispatch(request(service, trip, customerId, message))
        }
    }

    function request(service, trip, customerId, message) {
        return {
            type: tripConstants.SET_SERVICE_NOT_SERVICE_LINE,
            service,
            trip,
            customerId,
            message,
            serviceLine: false
        }
    }
}

function setServiceServiceLine(service, trip, customerId) {
    return dispatch => {
        let message = i18next.t('trips.maps.markers.onlyStopsOrigin')

        dispatch(request(service, trip, customerId, message))

    }

    function request(service, trip, customerId, message) {
        return {
            type: tripConstants.SET_SERVICE_SERVICE_LINE,
            service,
            trip,
            customerId,
            message,
            serviceLine: true
        }
    }
}

function closeModalRequestTrip() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: tripConstants.CLOSE_MODAL_REQUEST_TRIP }
    }
}

function closeModalSelectScheduledPickUp() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: tripConstants.CLOSE_MODAL_SELECT_SCHEDULE_PICKUP }
    }
}

function openModalRequestTrip(message) {
    return dispatch => {
        dispatch(request(message))
    }

    function request(message) {
        return { type: tripConstants.OPEN_MODAL_REQUEST_TRIP, message }
    }
}

function selectServiceOpen(trip, customerId, services) {
    return dispatch => {
        dispatch(request(trip, customerId, services))
    }

    function request(trip, customerId) {
        return {
            type: tripConstants.SELECT_SERVICE_TRIP_OPEN,
            trip,
            customerId,
            services,
        }
    }
}

function selectServiceClose() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: tripConstants.SELECT_SERVICE_TRIP_CLOSE }
    }
}

function acceptTrip(id) {
    return dispatch => {
        dispatch(request(id))
        tripService.acceptTrip(id).then(
            trip => {
                dispatch(success(trip))
                dispatch(alertActions.success(i18next.t('services.trips.acceptSuccess')))
            },
            error => {
                let detailError = ''
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(acceptTrip(id))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.trips.forbiddenUpdate')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
	                        detailError = parsedError.detail
                            switch (true) {
                                case detailError.includes('AcceptanceTimeExpired'):
                                    dispatch(alertActions.error(i18next.t('services.trips.acceptanceTimeExpired')))
                                    break
                                default: 
                                    dispatch(alertActions.error(i18next.t('services.trips.acceptError')))                                   
                                    break
                            }
                        } catch (e) {
                            dispatch(alertActions.error(i18next.t('services.trips.acceptError')))
                        }                        
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: tripConstants.ACCEPT_TRIP_REQUEST, id }
    }

    function success(trip) {
        return { type: tripConstants.ACCEPT_TRIP_SUCCESS, trip }
    }

    function failure(error) {
        return { type: tripConstants.ACCEPT_TRIP_FAILURE, error }
    }
}

function confirmTripVehicle(tripId, driverId, vehicleId, driverName, startDate = null, endDate = null) {
    return dispatch => {
        dispatch(request())
        tripService
            .confirmTripVehicle(tripId, vehicleId, driverId, driverName)
            .then(
                message => {
                    dispatch(success())
                    if (message?.length > 0)
                        dispatch(alertActions.warning(message))
                    else
                        dispatch(alertActions.success(i18next.t('services.trips.confirmTripVehicleSuccess')))
                    refreshTripsAfterCancelRequest(dispatch, startDate, endDate)
                },
                error => {
                    let detailError = 'Se ha producido un error'
                    if (error === 401 && userService.existRefreshToken()) {
                        dispatch(userActions.refreshToken())
                        dispatch(
                            confirmTripVehicle(
                                tripId,
                                driverId,
                                vehicleId,
                                driverName,
                            ),
                        )
                    } else {
                        try {
                            let parsedError = JSON.parse(error)
                            detailError = parsedError.detail
                            switch (true) {
                                case detailError.includes('RouteNotFound'):
                                    detailError = i18next.t('services.trips.requestTrip.RouteNotFound')
                                    break
                                case detailError.includes('TripNotFound'):
                                    detailError = i18next.t('services.trips.requestTrip.TripNotProcessed')
                                    break
                                case detailError.includes('ServiceNotFound'):
                                    detailError = i18next.t('services.trips.requestTrip.ServiceNotFound')
                                    break
                                case detailError.includes('VehicleNotFound'):
                                    detailError = i18next.t('services.trips.requestTrip.VehicleNotFound')
                                    break
                                default:
                                    break
                            }
                            dispatch(alertActions.error(detailError))
                        } catch (e) {
                            dispatch(alertActions.error(i18next.t('services.trips.requestTrip.TripNotProcessed')))
                        }
                    }
                    dispatch(failure(error))
                },
            )
    }

    function request() {
        return { type: tripConstants.ASSING_DRIVER_MANUAL_TRIP_REQUEST }
    }

    function success() {
        return { type: tripConstants.ASSING_DRIVER_MANUAL_TRIP_SUCCESS }
    }

    function failure(error) {
        return { type: tripConstants.ASSING_DRIVER_MANUAL_TRIP_FAILURE, error }
    }
}

function delegateTripVehicle(tripId, driverId, vehicleId, driverName, startDate = null, endDate = null) {
    return dispatch => {
        dispatch(request())
        tripService
            .delegateTripVehicle(tripId, vehicleId, driverId, driverName)
            .then(
                message => {
                    dispatch(success())
                    if (message?.length > 0)
                        dispatch(alertActions.warning(message))
                    else
                        dispatch(alertActions.success(i18next.t('services.trips.confirmTripVehicleSuccess')))
                    refreshTripsAfterCancelRequest(dispatch, startDate, endDate)
                },
                error => {
                    let detailError = 'Se ha producido un error'
                    if (error === 401 && userService.existRefreshToken()) {
                        dispatch(userActions.refreshToken())
                        dispatch(
                            delegateTripVehicle(
                                tripId,
                                driverId,
                                vehicleId,
                                driverName,
                            ),
                        )
                    } else {
                        try {
                            let parsedError = JSON.parse(error)
                            detailError = parsedError.detail
                            switch (true) {
                                case detailError.includes('RouteNotFound'):
                                    detailError = i18next.t('services.trips.requestTrip.RouteNotFound')
                                    break
                                case detailError.includes('TripNotFound'):
                                    detailError = i18next.t('services.trips.requestTrip.TripNotProcessed')
                                    break
                                case detailError.includes('ServiceNotFound'):
                                    detailError = i18next.t('services.trips.requestTrip.ServiceNotFound')
                                    break
                                case detailError.includes('VehicleNotFound'):
                                    detailError = i18next.t('services.trips.requestTrip.VehicleNotFound')
                                    break
                                default:
                                    break
                            }
                            dispatch(alertActions.error(detailError))
                        } catch (e) {
                            dispatch(alertActions.error(i18next.t('services.trips.requestTrip.TripNotProcessed')))
                        }
                    }
                    dispatch(failure(error))
                },
            )
    }

    function request() {
        return { type: tripConstants.ASSING_DRIVER_MANUAL_TRIP_REQUEST }
    }

    function success() {
        return { type: tripConstants.ASSING_DRIVER_MANUAL_TRIP_SUCCESS }
    }

    function failure(error) {
        return { type: tripConstants.ASSING_DRIVER_MANUAL_TRIP_FAILURE, error }
    }
}

function acceptModifyTrip(id, oldId) {
    return dispatch => {
        dispatch(request(id))
        tripService.acceptModifyTrip(id, oldId).then(
            trip => {
                dispatch(success(trip))
                dispatch(alertActions.success(i18next.t('services.trips.acceptSuccess')))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(acceptTrip(id))
                } else {
                    dispatch(alertActions.error(i18next.t('services.trips.acceptError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: tripConstants.ACCEPT_TRIP_REQUEST, id }
    }

    function success(trip) {
        return { type: tripConstants.ACCEPT_TRIP_SUCCESS, trip }
    }

    function failure(error) {
        return { type: tripConstants.ACCEPT_TRIP_FAILURE, error }
    }
}

function rejectTrip(id) {
    return dispatch => {
        dispatch(request(id))
        tripService.rejectTrip(id).then(
            trip => {
                dispatch(multipleTripsActions.updateScheduleReturn(false))
                dispatch(success(trip))
                dispatch(alertActions.success(i18next.t('services.trips.rejectSuccess')))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(rejectTrip(id))
                } else {
                    dispatch(alertActions.error(i18next.t('services.trips.rejectError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request(id) {
        return { type: tripConstants.REJECT_TRIP_REQUEST, id }
    }

    function success(trip) {
        return { type: tripConstants.REJECT_TRIP_SUCCESS, trip }
    }

    function failure(error) {
        return { type: tripConstants.REJECT_TRIP_FAILURE, error }
    }
}

function update(trip) {
    return dispatch => {
        dispatch(request())
        tripService.update(trip).then(
            trip => {
                dispatch(success(trip))
                dispatch(alertActions.success(i18next.t('services.trips.updateSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(update(trip))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.trips.forbiddenUpdate')))
                        break
                    default:
                        dispatch(alertActions.error(i18next.t('services.trips.updateError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: tripConstants.UPDATE_REQUEST }
    }

    function success(trip) {
        return { type: tripConstants.UPDATE_SUCCESS, trip }
    }

    function failure(error) {
        return { type: tripConstants.UPDATE_FAILURE, error }
    }
}

function insert(trip, customerId, serviceId, noRestriction, isRetry) {
    return dispatch => {
        dispatch(request())
        tripService.insert(trip, customerId, serviceId, noRestriction, isRetry).then(
            trip => {
                dispatch(success(trip))
                if (trip.serviceId == "61c3491a6630c659e50d069b" && trip.showWarning) {
                    dispatch(alertActions.warning(i18next.t('services.trips.SMPTMessage')))
                }
                if (trip.isAlternateSolution) {
                    dispatch(alertActions.warning(i18next.t('services.trips.warningAlternateSolution')))
                }
                dispatch(
                    alertActions.success(i18next.t('services.trips.insertSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(insert(trip, customerId, serviceId, noRestriction, isRetry))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.trips.forbiddenInsert')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = handleTripError(parsedError.detail, parsedError)
                            dispatch(alertActions.error(detailError))
                        }
                        catch (e) {
                            dispatch(alertActions.error(i18next.t('services.trips.requestTrip.RouteNotFound')))
                        }
                }
                dispatch(failure(error))
            }
        )
    }

    function request() {
        return { type: tripConstants.ADD_REQUEST }
    }

    function success(trip) {
        return { type: tripConstants.ADD_SUCCESS, trip }
    }

    function failure(error) {
        return { type: tripConstants.ADD_FAILURE, error }
    }
}

function modifyTrip(trip, customerId, serviceId) {
    return dispatch => {
        dispatch(request())
        tripService.modifyTrip(trip, customerId, serviceId).then(
            trip => {
                dispatch(success(trip))
                dispatch(alertActions.success(i18next.t('services.trips.insertSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(modifyTrip(trip, customerId, serviceId))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.trips.forbiddenUpdate')))
                        break
                    default:
                        try {
                            let parsedError = JSON.parse(error)
                            let detailError = handleTripError(parsedError.detail, parsedError)
                            dispatch(alertActions.error(detailError))
                        } catch (e) {
                            dispatch(alertActions.error(i18next.t('services.trips.requestTrip.RouteNotFound')))
                        }
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: tripConstants.ADD_REQUEST }
    }

    function success(trip) {
        return { type: tripConstants.ADD_SUCCESS, trip }
    }

    function failure(error) {
        return { type: tripConstants.ADD_FAILURE, error }
    }
}

function remove(id) {
    return dispatch => {
        dispatch(request())
        tripService._delete(id).then(
            trip => {
                dispatch(success(id))
                dispatch(alertActions.success(i18next.t('services.trips.deleteSuccess')))
            },
            error => {
                switch (error) {
                    case ERROR_CODES.unauthorized:
                        if (!userService.existRefreshToken()) return
                        dispatch(userActions.refreshToken())
                        dispatch(remove(id))
                        break
                    case ERROR_CODES.forbidden:
                        dispatch(alertActions.error(i18next.t('services.trips.forbiddenUpdate')))
                        break
                    default:
                        //dispatch(alertActions.error(error.toString()))
                        dispatch(alertActions.error(i18next.t('services.trips.deleteError')))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: tripConstants.DELETE_REQUEST }
    }

    function success(id) {
        return { type: tripConstants.DELETE_SUCCESS, id }
    }

    function failure(error) {
        return { type: tripConstants.DELETE_FAILURE, error }
    }
}

function getLocation(address) {
    return dispatch => {
        dispatch(request())
        googleMapsService.getLocation(address).then(
            location => {
                dispatch(success(location))
            },
            error => {
                if (error === 401 && userService.existRefreshToken()) {
                    dispatch(userActions.refreshToken())
                    dispatch(getLocation(address))
                }
                dispatch(failure(error))
            },
        )
    }

    function request() {
        return { type: tripConstants.GET_LOCATION_REQUEST }
    }

    function success(location) {
        return { type: tripConstants.GET_LOCATION_SUCCESS, location }
    }

    function failure(error) {
        return { type: tripConstants.GET_LOCATION_FAILURE, error }
    }
}

function setMarker(index, position) {
    return dispatch => {
        dispatch(request(index, position))
    }

    function request(index, position) {
        return { type: tripConstants.SET_MARKER, index, position }
    }
}

function clearMarker(index) {
    return dispatch => {
        dispatch(request(index))
    }

    function request(index) {
        return { type: tripConstants.CLEAR_MARKER, index }
    }
}

function clearAllResults() {
    return dispatch => {
        dispatch(request())
    }

    function request(index) {
        return { type: tripConstants.GETALL_CLEAR }
    }
}

function clearAllMarkers() {
    return dispatch => {
        dispatch(request())
    }

    function request(index) {
        return { type: tripConstants.CLEAR_ALL_MARKER }
    }
}

function clear() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: tripConstants.CLEAR_TRIP }
    }
}

function repeatTrip(trip, customer, isReturn) {
    return dispatch => {
        const requestTrip = {
            dropOffLocation: getDropOffLocationAddress(trip),
            pickUpLocation: getPickUpLocationAddress(trip),
            serviceId: trip.serviceId,
            wheelChairs: trip.hasWheelChair,
            quantityLuggage: trip.luggage,
            numPassengers: trip.numPassengers,
            fullName: trip.customerName,
            customerId: trip.customerId,
            customerToRepeat: customer,
            tripId: trip.tripId,
            minPickUpTime: trip.scheduleDropOffTime,
            outboundTripId: isReturn ? trip.tripId : null
        }
        dispatch(request(requestTrip))
    }

    function request(tripToReturn) {
        return { type: tripConstants.SET_REPEAT_TRIP, tripToReturn, isReturn: true }
    }
}

function setModifyTrip(trip, customer) {
    return dispatch => {
        let requestTrip = {
            dropOffLocation: {
                desc: {
                    description: trip.dropOffLocation.address,
                },
                location: {
                    address: trip.dropOffLocation.address,
                    desc: {
                        description: trip.dropOffLocation.address,
                    },
                    lat: trip.dropOffLocation.lat,
                    lng: trip.dropOffLocation.lon,
                    id: trip.dropOffLocation.id
                },
            },
            pickUpLocation: {
                desc: {
                    description: trip.pickUpLocation.address,
                },
                location: {
                    address: trip.pickUpLocation.address,
                    desc: {
                        description: trip.dropOffLocation.address,
                    },
                    lat: trip.pickUpLocation.lat,
                    lng: trip.pickUpLocation.lon,
                    id: trip.pickUpLocation.id
                },
            },
            tripId: trip.tripId,
            serviceId: trip.serviceId,
            passengerId: trip.passengerId,
            numPassengers: trip.numPassengers,
            quantityLuggage: trip.luggage,
            fullName: trip.customerName,
            customerId: trip.customerId,
            wheelChairs: trip.hasWheelChair,
        }
        dispatch(request(requestTrip, customer))
    }

    function request(trip, customers) {
        return { type: tripConstants.SET_MODIFY_TRIP, trip, customers }
    }
}

function cleanRepeatTrip() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: tripConstants.CLEAN_REPEAT_TRIP }
    }
}

function getAllKPI(startDate, finishDate) {
    console.log("startDate => ", startDate);
    console.log("finishDate => ", finishDate)
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request())
            tripService.getAllKPI(startDate, finishDate).then(
                results => {
                    dispatch(success(results))
                    resolve(results)
                },
                error => {
                    console.log("error in getAllKPI (actions) => ", error)
                    if (error === 401 && userService.existRefreshToken()) {
                        dispatch(userActions.refreshToken())
                        dispatch(getAllKPI(startDate, finishDate))
                    } else {
                        dispatch(alertActions.error(i18next.t('services.trips.getAllError')))
                    }
                    dispatch(failure(error))
                    reject('error')
                },
            )
        })

    }

    function request() {
        return { type: tripConstants.GETALL_KPI_REQUEST }
    }

    function success(results) {
        return { type: tripConstants.GETALL_KPI_SUCCESS, results }
    }

    function failure(error) {
        return { type: tripConstants.GETALL_KPI_FAILURE, error }
    }
}

function getJourneyKPI(startDate, finishDate) {
    console.log("startDate => ", startDate);
    console.log("finishDate => ", finishDate)
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request())
            serviceService.getJourneysKPI(startDate, finishDate).then(
                results => {
                    dispatch(success(results))
                    resolve(results)
                },
                error => {
                    console.log("error in getAllKPI (actions) => ", error)
                    if (error === 401 && userService.existRefreshToken()) {
                        dispatch(userActions.refreshToken())
                        dispatch(getJourneyKPI(startDate, finishDate))
                    }
                    dispatch(failure(error))
                    reject('error')
                },
            )
        })

    }

    function request() {
        return { type: tripConstants.GETALL_KPI_REQUEST }
    }

    function success(results) {
        return { type: tripConstants.GETALL_KPI_SUCCESS, results }
    }

    function failure(error) {
        return { type: tripConstants.GETALL_KPI_FAILURE, error }
    }
}

function getConfirmedTripsKPI(startDate, finishDate) {
    console.log("startDate => ", startDate);
    console.log("finishDate => ", finishDate)
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request())
            tripService.getConfirmedTripsKPI(startDate, finishDate).then(
                results => {
                    dispatch(success(results))
                    resolve(results)
                },
                error => {
                    console.log("error in getAllKPI (actions) => ", error)
                    if (error === 401 && userService.existRefreshToken()) {
                        dispatch(userActions.refreshToken())
                        dispatch(getConfirmedTripsKPI(startDate, finishDate))
                    } else {
                        dispatch(alertActions.error(i18next.t('services.trips.getAllError')))
                    }
                    dispatch(failure(error))
                    reject('error')
                },
            )
        })

    }

    function request() {
        return { type: tripConstants.GETALL_KPI_REQUEST }
    }

    function success(results) {
        return { type: tripConstants.GETALL_KPI_SUCCESS, results }
    }

    function failure(error) {
        return { type: tripConstants.GETALL_KPI_FAILURE, error }
    }
}

function getConfirmedGroupedTripsKPI(startDate, finishDate) {
    console.log("startDate => ", startDate);
    console.log("finishDate => ", finishDate)
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request())
            serviceService.getConfirmedGroupedTripsKPI(startDate, finishDate).then(
                results => {
                    dispatch(success(results))
                    resolve(results)
                },
                error => {
                    console.log("error in getAllKPI (actions) => ", error)
                    if (error === 401 && userService.existRefreshToken()) {
                        dispatch(userActions.refreshToken())
                        dispatch(getConfirmedGroupedTripsKPI(startDate, finishDate))
                    } else {
                        dispatch(alertActions.error(i18next.t('services.trips.getAllError')))
                    }
                    dispatch(failure(error))
                    reject('error')
                },
            )
        })

    }

    function request() {
        return { type: tripConstants.GET_CONFIRMED_GROUPEDKPI_REQUEST }
    }

    function success(results) {
        return { type: tripConstants.GET_CONFIRMED_GROUPEDKPI_SUCCESS, results }
    }

    function failure(error) {
        return { type: tripConstants.GET_CONFIRMED_GROUPEDKPI_FAILURE, error }
    }
}

function closeReturnTripModal() {
    return dispatch => {
        dispatch(request())
    }

    function request() {
        return { type: tripConstants.CLOSE_RETURN_TRIP_MODAL }
    }
}
function makeEnum(enumObject) {
    let all = []
    for (let key in enumObject) {
        all.push({
            id: key,
            name: enumObject[key],
        })
    }
    return all
}

function filterPlainArrayContains(array, filters) {
    const filterKeys = Object.keys(filters)
    return array.filter(item => {
        // validates all filter criteria
        return filterKeys.every(key => {
            // ignores an empty filter
            if (!filters[key].length) return true
            return filters[key].find(filter =>
                moment(getValue(item[key]))
                    .format('DD/MM/YYYY')
                    .includes(moment(filter).format('DD/MM/YYYY')),
            )
        })
    })
}

function saveTableFilters(tableFilters) {
    return dispatch => {
        dispatch(save(tableFilters))
    }

    function save(tableFilters) {
        return { type: tripConstants.SAVE_TABLE_FILTERS, tableFilters }
    }
}

function cleanFilters() {
    return dispatch => {
        dispatch(clean())
    }

    function clean() {
        return { type: tripConstants.CLEAN_FILTER_TRIPS }
    }
}